import { CacheClient } from './services/cache';
import { getCookie } from './utils';
export class ChordAnalyticsIdentifiers {
    constructor() {
        this.cache = new CacheClient({ baseUrl: window?.location?.origin });
    }
    getIdentifiers = () => {
        const attributes = {};
        try {
            const anonymousId = getCookie('ajs_anonymous_id');
            if (anonymousId) {
                attributes._cdpAnonymousId = anonymousId;
            }
            const googleClientId = getCookie('_ga')?.substring(6);
            if (googleClientId) {
                attributes._googleClientId = googleClientId;
            }
        }
        catch (e) {
            console.error(e);
        }
        return attributes;
    };
    /**
     * Adds identifiers as cart attributes, so they can be included in server-side events.
     * By default, the CDP anonoymous ID is added as a `_cdpAnonymousId` attribute.
     */
    syncIdentifiersToCart = async () => {
        try {
            const cart = await this.cache.getCart();
            const attributesToAdd = this.getIdentifiers();
            const identifiersAlreadyExist = Object.keys(attributesToAdd).every((key) => cart?.attributes?.[key] === attributesToAdd[key]);
            if (identifiersAlreadyExist)
                return undefined;
            const updated = await this.cache.updateCartAttributes(attributesToAdd);
            return updated;
        }
        catch (e) {
            console.error(e);
        }
        return undefined;
    };
}

export const ensureStartingSlash = (url) => {
    return url.startsWith('/') ? url : `/${url}`;
};
/**
 * This is a simple wrapper around the Shopify AJAX API.
 * https://shopify.dev/docs/api/ajax/reference
 */
export class ShopifyAjaxClient {
    baseUrl;
    localePath;
    constructor(props) {
        this.baseUrl = props?.baseUrl;
        this.localePath = props?.localePath;
    }
    constructUrl = (path) => {
        const defaultBaseUrl = window?.location?.origin;
        const defaultLocalePath = window?.Shopify?.routes?.root || '/';
        const baseUrl = this.baseUrl ?? defaultBaseUrl;
        const localePath = this.localePath ?? defaultLocalePath;
        const pathWithLocale = `${localePath}${path}`;
        if (!baseUrl)
            return ensureStartingSlash(pathWithLocale);
        return new URL(pathWithLocale, baseUrl);
    };
    async getCart() {
        const url = this.constructUrl(`cart.js`);
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Request failed');
        }
        return response.json();
    }
    async updateCartAttributes(attributes) {
        const url = this.constructUrl(`cart/update.js`);
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                attributes,
            }),
        });
        if (!response.ok) {
            throw new Error('Request failed');
        }
        return response.json();
    }
}

import { ShopifyAjaxClient } from './ajax';
export class CacheClient {
    ajaxClient;
    cart;
    constructor(options) {
        this.ajaxClient = new ShopifyAjaxClient({ baseUrl: options?.baseUrl });
    }
    updateCartAttributes = async (attributes) => {
        this.cart = await this.ajaxClient.updateCartAttributes(attributes);
        return this.cart;
    };
    getCart = async (force = false) => {
        if (!force && this.cart)
            return this.cart;
        this.cart = await this.ajaxClient.getCart();
        return this.cart;
    };
}
